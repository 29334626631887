<ng-container
    *ngIf="{
        isOpen: (appState.linksDialogState.isOpen | async) ?? false,
        externalLinks: (appState.linksDialogState.externalLinks | async) ?? [],
        dwDefinitions: appState.linksDialogState.dwDefinitions | async,
        linksTotalCount: appState.linksDialogState.linksTotalCount | async,
    } as $"
>
    <interacta-dialog
        [isOpen]="$.isOpen"
        [size]="'extra-large'"
        (closing)="closeDialog()"
    >
        <h4 class="typo-t4" title>
            {{ 'DIGITAL_WORKPLACE.USEFUL_LINKS' | translate }}
        </h4>
        <div
            class="mt-20 flex h-screen flex-col gap-y-24 overflow-y-auto overflow-x-hidden"
            content
        >
            <interacta-input-text-v2
                *ngIf="($.linksTotalCount ?? 0) > 20"
                class="w-1/2"
                [control]="search"
                [icon]="'search-left'"
                [iconClickable]="false"
                [iconPosition]="'right'"
            />
            @if ($.externalLinks.length) {
                <interacta-external-links [externalLinks]="$.externalLinks" />
            }
            <div *ngIf="$.dwDefinitions?.length" class="flex flex-col gap-y-44">
                <interacta-accordion
                    *ngFor="let digitalWorkplaceDefinition of $.dwDefinitions"
                    [isOpen]="
                        showusefulLinks[digitalWorkplaceDefinition.id] ?? true
                    "
                    [label]="
                        digitalWorkplaceDefinition.name
                            | currentLanguageV2: true
                    "
                    [size]="'extra-large'"
                    (toggleOpen)="
                        toggleAccordion(digitalWorkplaceDefinition.id)
                    "
                >
                    <div
                        class="bg-surface-B flex flex-col gap-y-24 rounded-2xl p-16"
                    >
                        @for (
                            widget of digitalWorkplaceDefinition.widgets;
                            track widget.id
                        ) {
                            <div class="flex flex-col gap-y-24">
                                @if (widget.tag === WidgetType.USEFUL_LINK) {
                                    <div class="flex flex-col gap-y-24">
                                        <h5 class="typo-t5">
                                            {{
                                                widget.name | currentLanguageV2
                                            }}
                                        </h5>

                                        <div
                                            class="flex flex-wrap gap-x-32 gap-y-40"
                                        >
                                            @for (
                                                group of widget.linksGroups;
                                                track group.uuid
                                            ) {
                                                <interacta-useful-link
                                                    [idx]="$index"
                                                    [links]="group.links"
                                                    [name]="
                                                        group.name
                                                            | currentLanguageV2
                                                    "
                                                    (imageLoadError)="
                                                        temporaryLinkErrorHandlerService.handleUsefulLinkError(
                                                            $event
                                                        )
                                                    "
                                                />
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                        }
                    </div>
                </interacta-accordion>
            </div>

            <div
                *ngIf="!$.externalLinks?.length && !$.dwDefinitions?.length"
                class="flex grow items-center justify-center"
            >
                <interacta-no-result
                    [imageName]="'no-results'"
                    [subtitle]="'DIGITAL_WORKPLACE.CHANGE_SEARCH' | translate"
                    [title]="'DIGITAL_WORKPLACE.NO_LINK' | translate"
                />
            </div>
        </div>
    </interacta-dialog>
</ng-container>
