import {
    ChangeDetectionStrategy,
    Component,
    OnDestroy,
    OnInit,
    inject,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { TemporaryLinkErrorHandlerService } from '@modules/communities/services/temporary-link-error-handler.service';
import { GoogleAnalyticsService } from '@modules/core/services/google-analytics.service';
import { WidgetType } from '@modules/digital-workplace/models/digital-workplace.model';
import { LinkDialogStateService } from '@modules/state/services/links-dialog-state.service';
import { StateService } from '@modules/state/services/state.service';
import { Subject, debounceTime, filter, map, takeUntil, tap } from 'rxjs';

@Component({
    selector: 'interacta-links-dialog',
    templateUrl: './links-dialog.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinksDialogComponent implements OnInit, OnDestroy {
    readonly WidgetType = WidgetType;
    search = new FormControl<string>('');
    showusefulLinks: Partial<Record<number, boolean>> = {};

    private readonly destroy$ = new Subject<void>();

    temporaryLinkErrorHandlerService = inject(TemporaryLinkErrorHandlerService);

    constructor(
        public appState: StateService,
        private linkDialogStateService: LinkDialogStateService,
        private googleAnalyticsService: GoogleAnalyticsService,
    ) {
        this.appState.linksDialogState.isOpen
            .asObservable()
            .pipe(takeUntil(this.destroy$))
            .subscribe((value) => {
                if (value)
                    this.googleAnalyticsService.sendScreenView('Useful Links');
            });
    }

    ngOnInit(): void {
        this.search.valueChanges
            .pipe(
                debounceTime(300),
                filter((value) => !value || value.length > 2),
                map((value) => (value === '' ? null : value)),
                takeUntil(this.destroy$),
            )
            .subscribe((search) =>
                this.linkDialogStateService.getLinks(search ?? undefined),
            );

        this.appState.linksDialogState.dwDefinitions
            .pipe(
                takeUntil(this.destroy$),
                tap(
                    (dwDefs) =>
                        dwDefs &&
                        dwDefs.forEach(
                            (dwDef) => (this.showusefulLinks[dwDef.id] = true),
                        ),
                ),
            )
            .subscribe();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
    }

    toggleAccordion(dwId: number): void {
        this.showusefulLinks[dwId] = !this.showusefulLinks[dwId];
    }

    closeDialog(): void {
        this.search.reset();
        this.linkDialogStateService.close();
    }
}
